<template>
  <div class="container-fluid wrapper">
    <div class="row">
      <div class="col-sm-12 col-md-12 content-wrapper justify-content-center">
        <div class="header align-self-center">
          <img :src="logoPath" alt="logo" class="p-4" style="height: 140px" />
        </div>
        <div class="iniciar-sesion mt-5">
          <h3>{{ $t("pages.login.description") }}</h3>
          <h5>{{ $t("pages.reset_password.init_session") }}</h5>
        </div>
        <div>
          <form @submit.prevent="recoverPassword" class="signin-form mx-auto mt-2">
            <div class="alert alert-danger" role="alert" v-if="error">
              {{ error }}
            </div>

            <name-field :form="form" name="name" id="name" />

            <div class="text-center">
              <vue-button
                :title="$t('pages.reset_password.reset_now')"
                :loading="isLoading"
                class="btn btn-primary p-3 text-uppercase"
                type="submit"
              />
            </div>
            <div class="text-center mt-3">
              <router-link :to="{ name: 'login' }">{{
                $t("pages.reset_password.login_return")
              }}</router-link>
            </div>
            <div class="text-center mt-2 mb-3" style="font-size: 12px">
              {{ copyright }}
            </div>
          </form>
        </div>
        <div class="d-flex justify-content-center">
          <google-play-badge class="mr-3" />
          <apple-store-badge />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/user";

import { mapGetters } from "vuex";
import { VueButton, Form } from "@/components/form";
import { RequiredRule } from "@/components/form/data/rules";
import { NameField } from "@/pages/auth/fields";
import * as Sentry from "@sentry/vue";
import { ModalAlert } from "@/components/modal";

import { AppleStoreBadge, GooglePlayBadge } from "@/components";

export default {
  layout: "BasicLayout",
  middleware: "auth",
  components: {
    VueButton,
    NameField,
    AppleStoreBadge,
    GooglePlayBadge,
  },
  data() {
    return {
      error: null,
      form: new Form({
        data: {
          name: null,
        },
      }),
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("app", ["appVersion", "logoPath", "copyright"]),
    ...mapGetters("auth", ["logoPath"]),
    fieldsRules() {
      return [
        new RequiredRule({
          name: "name",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
      ];
    },
  },
  methods: {
    clear() {
      this.form.reset();
      this.form.clear();
    },
    async recoverPassword() {
      this.form.setFieldsRules(this.fieldsRules);

      if (this.form.validate()) {
        this.isLoading = true;
        this.error = "";
        const data = this.form.data();

        Sentry.setContext("recoverData", {
          name: data.name,
        });

        api
          .recoverPassword(data)
          .then(async () => {
            this.clear();
            ModalAlert.success({
              title: this.$t("pages.reset_password.init_session"),
              message: this.$t("pages.reset_password.reset_password_message_info"),
            });
          })
          .catch((error) => {
            if (error.message.toLowerCase() === "network error") {
              this.error = this.$t("components.messages.network_error");
            } else if (error.response) {
              if (error.response.status === 404) {
                this.error = this.$t("auth.user_not_found");
                this.clear();
              } else {
                //unsigned error
                Sentry.captureException(error);
                this.error = this.$t("components.messages.error");
              }
            } else {
              //unsigned error
              Sentry.captureException(error);
              this.error = this.$t("components.messages.error");
            }
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  &,
  & > .row,
  & > .row > .col {
    height: 100vh;
  }
}

.header,
.signin-form,
.footer {
}

.header {
  height: 100px;
}

.footer {
  background-color: #80ef57;
}

.signin-form {
  max-width: 400px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;

  &.thumbnail {
    background-color: #afafaf;
    background-image: linear-gradient(135deg, rgba(167, 18, 181, 0.4) 0%, rgba(58, 0, 159, 1) 100%),
      url("../../assets/image.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

.left {
  height: 100vh;
  width: 100%;
}

.iniciar-sesion {
  color: #000000;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 67.2px;
  text-align: center;
}

.quieres-formar-part {
  height: 134px;
  width: 445px;
  color: #ffffff;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
}

.enlace {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
}

.btn-primary {
  color: #fff;
  background-color: #3a009f;
  border-color: #3a009f;
}
</style>
